import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import LoadingSpinner from '../../common/LoadingSpinner';

interface User {
    _id: string;
    email: string;
    civilite: string;
    firstName: string;
    lastName: string;
    pays: string;
    role: string;
    createdAt: Date;
    updatedAt: Date;
    lastLogin: Date | null;
    verifiedUser: boolean;
    verifiedDate: Date | null;
    adresse: string;
    phone: string;
    preferences: any;
    ville: string;
    codePostal: string;
    societe: string;
    tva: string;
    langue: string;
    timeZone: string;
    allowedMarketing: boolean;
    profilePicture?: string;
}

const UserDetails: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userAuth = JSON.parse(localStorage.getItem('user') || '{}');
                const token = userAuth.token;

                if (!token) {
                    throw new Error('Token non trouvé');
                }

                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/users/${id}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`Erreur HTTP ! statut : ${response.status}`);
                }

                const data = await response.json();
                setUser(data);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'utilisateur:', error);
                setError('Erreur lors de la récupération de l\'utilisateur');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    if (isLoading) return <LoadingSpinner />;
    if (error) return <div className="p-4 text-red-500">{error}</div>;
    if (!user) return <div className="p-4">Utilisateur non trouvé</div>;

    return (
        <div className="p-8">
            <div className="flex items-center justify-between mb-6">
                <h1 className="text-2xl font-bold">Détails de l'utilisateur</h1>
                <button
                    onClick={() => navigate('/users')}
                    className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
                >
                    Retour à la liste
                </button>
            </div>

            <div className="bg-white shadow rounded-lg p-6">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h3 className="text-gray-500">Email</h3>
                        <p className="font-semibold">{user.email}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Civilité</h3>
                        <p className="font-semibold">{user.civilite || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Prénom</h3>
                        <p className="font-semibold">{user.firstName || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Nom</h3>
                        <p className="font-semibold">{user.lastName || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Pays</h3>
                        <p className="font-semibold">{user.pays || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Rôle</h3>
                        <p className="font-semibold">{user.role}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Date d'inscription</h3>
                        <p className="font-semibold">
                            {user.createdAt ? format(new Date(user.createdAt), 'dd/MM/yyyy HH:mm') : '-'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;