import React from 'react';

interface HALMetadataProps {
    metadata: any;
}

const HALMetadata: React.FC<HALMetadataProps> = ({ metadata }) => {
    // Structure temporaire pour la démonstration
    const mockMetadata = {
        metadata: {
            title: 'La responsabilité des dirigeants dans le droit des sociétés moderne',
            type: 'Article scientifique',
            domain: 'Droit',
            subDomain: 'Droit des sociétés',
            level: 'avancé',
            language: 'fr',
        },
        tags: {
            primary: ['droit des sociétés', 'responsabilité', 'dirigeant', 'gouvernance', 'gestion'],
            secondary: ['jurisprudence', 'doctrine', 'conseil d\'administration', 'assemblée générale'],
            technical: ['Code de commerce', 'responsabilité civile', 'mandat social'],
            practical: ['gestion d\'entreprise', 'conformité', 'audit', 'prévention']
        },
        classification: {
            mainCategory: 'Sciences juridiques',
            subCategories: ['Droit privé', 'Droit des affaires'],
            nature: 'Recherche académique'
        },
        contentQuality: {
            completeness: 0.95,
            clarity: 0.90,
            technicalAccuracy: 0.85,
            practicalRelevance: 0.80
        }
    };

    const getPercentage = (value: number) => Math.round(value * 100);

    const TagList: React.FC<{ title: string; tags: string[]; color: string }> = ({ title, tags, color }) => (
        <div>
            <h3 className="text-lg font-medium mb-2">{title}</h3>
            <div className="flex flex-wrap gap-2">
                {tags.length === 0 ? (
                    <span className="text-gray-500 italic">Aucun tag</span>
                ) : (
                    tags.map((tag, index) => (
                        <span
                            key={index}
                            className={`px-3 py-1 rounded-full text-sm font-medium ${color}`}
                        >
                            {tag}
                        </span>
                    ))
                )}
            </div>
        </div>
    );

    const QualityIndicator: React.FC<{ label: string; value: number }> = ({ label, value }) => (
        <div className="flex items-center gap-2">
            <span className="text-gray-600 w-32">{label}:</span>
            <div className="flex-1">
                <div className="w-full bg-gray-200 rounded h-2">
                    <div
                        className="bg-blue-500 rounded h-2 transition-all duration-300"
                        style={{ width: `${getPercentage(value)}%` }}
                    ></div>
                </div>
                <span className="text-sm text-gray-500 mt-1 inline-block">
                    {getPercentage(value)}%
                </span>
            </div>
        </div>
    );

    return (
        <div className="mt-8 space-y-8">
            {/* Métadonnées automatiques */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Métadonnées automatiques</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                        <div><span className="text-gray-600">Domaine:</span> {mockMetadata.metadata.domain}</div>
                        <div><span className="text-gray-600">Sous-domaine:</span> {mockMetadata.metadata.subDomain}</div>
                        <div><span className="text-gray-600">Type:</span> {mockMetadata.metadata.type}</div>
                    </div>
                    <div className="space-y-2">
                        <div><span className="text-gray-600">Niveau:</span> {mockMetadata.metadata.level}</div>
                        <div><span className="text-gray-600">Langue:</span> {mockMetadata.metadata.language}</div>
                    </div>
                </div>
            </div>

            {/* Tags */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Tags générés</h2>
                <div className="space-y-6">
                    <TagList
                        title="Tags principaux"
                        tags={mockMetadata.tags.primary}
                        color="bg-blue-100 text-blue-800"
                    />
                    <TagList
                        title="Tags secondaires"
                        tags={mockMetadata.tags.secondary}
                        color="bg-gray-100 text-gray-800"
                    />
                    <TagList
                        title="Tags techniques"
                        tags={mockMetadata.tags.technical}
                        color="bg-green-100 text-green-800"
                    />
                    <TagList
                        title="Tags pratiques"
                        tags={mockMetadata.tags.practical}
                        color="bg-yellow-100 text-yellow-800"
                    />
                </div>
            </div>

            {/* Classification */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Classification</h2>
                <div className="space-y-4">
                    <div>
                        <h3 className="text-gray-600">Catégorie principale</h3>
                        <p className="font-semibold">{mockMetadata.classification.mainCategory}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-600">Sous-catégories</h3>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {mockMetadata.classification.subCategories.map((cat: string, index: number) => (
                                <span key={index} className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full text-sm">
                                    {cat}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h3 className="text-gray-600">Nature</h3>
                        <p className="font-semibold">{mockMetadata.classification.nature}</p>
                    </div>
                </div>
            </div>

            {/* Qualité du contenu */}
            <div className="bg-white rounded-lg shadow-md p-6">
                <h2 className="text-xl font-medium mb-4">Évaluation automatique</h2>
                <div className="space-y-4">
                    <QualityIndicator
                        label="Complétude"
                        value={mockMetadata.contentQuality.completeness}
                    />
                    <QualityIndicator
                        label="Clarté"
                        value={mockMetadata.contentQuality.clarity}
                    />
                    <QualityIndicator
                        label="Précision"
                        value={mockMetadata.contentQuality.technicalAccuracy}
                    />
                    <QualityIndicator
                        label="Pertinence"
                        value={mockMetadata.contentQuality.practicalRelevance}
                    />
                </div>
            </div>
        </div>
    );
};

export default HALMetadata;