import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  HomeIcon,
  UsersIcon,
  FolderIcon,
  KeyIcon,
  CreditCardIcon,
  ClipboardIcon,
  CogIcon,
  BookOpenIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isRessourcesOpen, setIsRessourcesOpen] = useState(
    location.pathname.startsWith('/ressources')
  );

  const navigateToDashboard = () => navigate('/dashboard');

  const isActive = (path: string) => {
    if (path === '/folders') {
      return location.pathname.startsWith('/folders');
    }
    if (path === '/ressources') {
      return location.pathname.startsWith('/ressources');
    }
    return location.pathname === path;
  };

  return (
    <aside className="fixed w-64 bg-white h-screen border-r border-gray-200 z-10" aria-label="Sidebar">
      <div className="overflow-y-auto py-4 px-3 h-full">
        <div className="mb-5" onClick={navigateToDashboard} style={{ cursor: 'pointer' }}>
          <img
            src={`${process.env.PUBLIC_URL}/logos/logo-macadamia-v2.png`}
            alt="Logo Macadamia"
            className="h-8 w-auto ml-2"
          />
        </div>
        <ul className="space-y-2">
          <li>
            <a
              href="/dashboard"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/dashboard') ? 'bg-gray-200' : ''
                }`}
            >
              <HomeIcon className="w-5 h-5" />
              <span className="ml-3">Dashboard</span>
            </a>
          </li>
          <li>
            <a
              href="/users"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/users') ? 'bg-gray-200' : ''
                }`}
            >
              <UsersIcon className="w-5 h-5" />
              <span className="ml-3">Utilisateurs</span>
            </a>
          </li>
          <li>
            <a
              href="/folders"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/folders') ? 'bg-gray-200' : ''
                }`}
            >
              <FolderIcon className="w-5 h-5" />
              <span className="ml-3">Dossiers</span>
            </a>
          </li>
          <li>
            <div>
              <button
                onClick={() => setIsRessourcesOpen(!isRessourcesOpen)}
                className={`w-full flex items-center justify-between p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/ressources') ? 'bg-gray-200' : ''
                  }`}
              >
                <div className="flex items-center">
                  <BookOpenIcon className="w-5 h-5" />
                  <span className="ml-3">Ressources</span>
                </div>
                <ChevronDownIcon
                  className={`w-4 h-4 transform transition-transform duration-200 ${isRessourcesOpen ? 'rotate-180' : ''
                    }`}
                />
              </button>
              <div className={`transition-all duration-200 ease-in-out ${isRessourcesOpen ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                }`}>
                <ul className="pl-6 mt-2 space-y-2">
                  <li>
                    <a
                      href="/ressources"
                      className={`flex items-center p-2 text-sm font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${location.pathname === '/ressources' ? 'bg-gray-200' : ''
                        }`}
                    >
                      <span className="ml-3">Vue générale</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/ressources/hal"
                      className={`flex items-center p-2 text-sm font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${location.pathname === '/ressources/hal' ? 'bg-gray-200' : ''
                        }`}
                    >
                      <span className="ml-3">HAL</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/ressources/lettre-numerique"
                      className={`flex items-center p-2 text-sm font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${location.pathname === '/ressources/lettre-numerique' ? 'bg-gray-200' : ''
                        }`}
                    >
                      <span className="ml-3">Lettre du Numérique</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <a
              href="/licenses"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/licenses') ? 'bg-gray-200' : ''
                }`}
            >
              <KeyIcon className="w-5 h-5" />
              <span className="ml-3">Licences</span>
            </a>
          </li>
          <li>
            <a
              href="/payments"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/payments') ? 'bg-gray-200' : ''
                }`}
            >
              <CreditCardIcon className="w-5 h-5" />
              <span className="ml-3">Paiements</span>
            </a>
          </li>
          <li>
            <a
              href="/logs"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/logs') ? 'bg-gray-200' : ''
                }`}
            >
              <ClipboardIcon className="w-5 h-5" />
              <span className="ml-3">Logs</span>
            </a>
          </li>
          <li>
            <a
              href="/settings"
              className={`flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 ${isActive('/settings') ? 'bg-gray-200' : ''
                }`}
            >
              <CogIcon className="w-5 h-5" />
              <span className="ml-3">Paramètres</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;