import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import LoadingSpinner from '../../common/LoadingSpinner';

interface UserMetadata {
    domainsOfExpertise: Array<{
        name: string;
        score: number;
        dossierCount: number;
    }>;
    expertise: {
        technical: {
            tags: Array<{ name: string; score: number; }>;
        };
        practical: {
            tags: Array<{ name: string; score: number; }>;
        };
    };
    level: {
        overall: string;
        distribution: Record<string, number>;
    };
    statistics: {
        totalDossiers: number;
        visibilityDistribution: {
            public: number;
            private: number;
        };
        averageQuality: {
            completeness: number;
            clarity: number;
            technicalAccuracy: number;
            practicalRelevance: number;
        };
    };
    lastCalculated: Date;
}

interface User {
    _id: string;
    email: string;
    civilite: string;
    firstName: string;
    lastName: string;
    pays: string;
    role: string;
    createdAt: Date;
    updatedAt: Date;
    lastLogin: Date | null;
    verifiedUser: boolean;
    verifiedDate: Date | null;
    adresse: string;
    phone: string;
    preferences: any;
    ville: string;
    codePostal: string;
    societe: string;
    tva: string;
    langue: string;
    timeZone: string;
    allowedMarketing: boolean;
    profilePicture?: string;
}

const UserDetails: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [metadata, setMetadata] = useState<UserMetadata | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userAuth = JSON.parse(localStorage.getItem('user') || '{}');
                const token = userAuth.token;

                if (!token) {
                    throw new Error('Token non trouvé');
                }

                // Séparation des appels pour gérer les erreurs individuellement
                const userResponse = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/users/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!userResponse.ok) {
                    throw new Error(`Erreur lors de la récupération de l'utilisateur`);
                }

                const userData = await userResponse.json();
                setUser(userData);

                try {
                    const metadataResponse = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/user-metadata/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (metadataResponse.ok) {
                        const metadataData = await metadataResponse.json();
                        setMetadata(metadataData.data);
                    } else if (metadataResponse.status === 404) {
                        console.log('Aucune métadonnée disponible pour cet utilisateur');
                        setMetadata(null);
                    } else {
                        console.error('Erreur lors de la récupération des métadonnées');
                    }
                } catch (error) {
                    console.error('Erreur lors de la récupération des métadonnées:', error);
                    setMetadata(null);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
                setError('Erreur lors de la récupération des données');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (isLoading) return <LoadingSpinner />;
    if (error) return <div className="p-4 text-red-500">{error}</div>;
    if (!user) return <div className="p-4">Utilisateur non trouvé</div>;

    const renderQualityScore = (score: number) => {
        return (
            <div className="relative w-full bg-gray-200 h-2 rounded">
                <div
                    className="absolute top-0 left-0 h-2 bg-blue-500 rounded"
                    style={{ width: `${score * 100}%` }}
                />
            </div>
        );
    };

    return (
        <div className="p-8">
            <div className="flex items-center justify-between mb-6">
                <h1 className="text-2xl font-bold">Détails de l'utilisateur</h1>
                <button
                    onClick={() => navigate('/users')}
                    className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
                >
                    Retour à la liste
                </button>
            </div>

            {/* Informations de base */}
            <div className="bg-white shadow rounded-lg p-6 mb-6">
                <h2 className="text-xl font-semibold mb-4">Informations personnelles</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h3 className="text-gray-500">Email</h3>
                        <p className="font-semibold">{user.email}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Civilité</h3>
                        <p className="font-semibold">{user.civilite || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Prénom</h3>
                        <p className="font-semibold">{user.firstName || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Nom</h3>
                        <p className="font-semibold">{user.lastName || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Pays</h3>
                        <p className="font-semibold">{user.pays || '-'}</p>
                    </div>
                    <div>
                        <h3 className="text-gray-500">Rôle</h3>
                        <p className="font-semibold">{user.role}</p>
                    </div>
                </div>
            </div>

            {metadata ? (
                <>
                    {/* Niveau et statistiques */}
                    <div className="bg-white shadow rounded-lg p-6 mb-6">
                        <h2 className="text-xl font-semibold mb-4">Niveau et statistiques</h2>
                        <div className="grid grid-cols-2 gap-6">
                            <div>
                                <h3 className="text-gray-500 mb-2">Niveau global</h3>
                                <p className="font-semibold capitalize">{metadata.level.overall}</p>
                                <div className="mt-4">
                                    <h4 className="text-gray-500 mb-2">Distribution par niveau</h4>
                                    {Object.entries(metadata.level.distribution).map(([level, percentage]) => (
                                        <div key={level} className="mb-2">
                                            <p className="text-sm capitalize">{level}: {percentage}%</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <h3 className="text-gray-500 mb-2">Statistiques</h3>
                                <p className="mb-2">Total des dossiers: {metadata.statistics.totalDossiers}</p>
                                <div className="mb-4">
                                    <p className="text-sm">Visibilité:</p>
                                    <p className="text-sm">Public: {metadata.statistics.visibilityDistribution.public}</p>
                                    <p className="text-sm">Privé: {metadata.statistics.visibilityDistribution.private}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Qualité moyenne */}
                    <div className="bg-white shadow rounded-lg p-6 mb-6">
                        <h2 className="text-xl font-semibold mb-4">Qualité moyenne</h2>
                        <div className="grid gap-4">
                            <div>
                                <h3 className="text-gray-500 mb-2">Complétude</h3>
                                {renderQualityScore(metadata.statistics.averageQuality.completeness)}
                                <p className="text-sm mt-1">
                                    {(metadata.statistics.averageQuality.completeness * 100).toFixed(1)}%
                                </p>
                            </div>
                            <div>
                                <h3 className="text-gray-500 mb-2">Clarté</h3>
                                {renderQualityScore(metadata.statistics.averageQuality.clarity)}
                                <p className="text-sm mt-1">
                                    {(metadata.statistics.averageQuality.clarity * 100).toFixed(1)}%
                                </p>
                            </div>
                            <div>
                                <h3 className="text-gray-500 mb-2">Précision technique</h3>
                                {renderQualityScore(metadata.statistics.averageQuality.technicalAccuracy)}
                                <p className="text-sm mt-1">
                                    {(metadata.statistics.averageQuality.technicalAccuracy * 100).toFixed(1)}%
                                </p>
                            </div>
                            <div>
                                <h3 className="text-gray-500 mb-2">Pertinence pratique</h3>
                                {renderQualityScore(metadata.statistics.averageQuality.practicalRelevance)}
                                <p className="text-sm mt-1">
                                    {(metadata.statistics.averageQuality.practicalRelevance * 100).toFixed(1)}%
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Domaines d'expertise */}
                    <div className="bg-white shadow rounded-lg p-6 mb-6">
                        <h2 className="text-xl font-semibold mb-4">Domaines d'expertise</h2>
                        <div className="grid gap-4">
                            {metadata.domainsOfExpertise.map((domain, index) => (
                                <div key={index} className="border-b pb-2">
                                    <div className="flex justify-between items-center mb-2">
                                        <h3 className="font-medium">{domain.name}</h3>
                                        <span className="text-sm text-gray-500">
                                            {domain.dossierCount} dossiers
                                        </span>
                                    </div>
                                    {renderQualityScore(domain.score)}
                                    <p className="text-sm mt-1">{(domain.score * 100).toFixed(1)}%</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Tags d'expertise */}
                    <div className="bg-white shadow rounded-lg p-6">
                        <h2 className="text-xl font-semibold mb-4">Tags d'expertise</h2>
                        <div className="grid grid-cols-2 gap-6">
                            <div>
                                <h3 className="text-gray-500 mb-4">Technique</h3>
                                <div className="flex flex-wrap gap-2">
                                    {metadata.expertise.technical.tags.map((tag, index) => (
                                        <div
                                            key={index}
                                            className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm"
                                        >
                                            {tag.name} ({(tag.score * 100).toFixed(0)}%)
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <h3 className="text-gray-500 mb-4">Pratique</h3>
                                <div className="flex flex-wrap gap-2">
                                    {metadata.expertise.practical.tags.map((tag, index) => (
                                        <div
                                            key={index}
                                            className="bg-green-100 text-green-800 px-3 py-1 rounded-full text-sm"
                                        >
                                            {tag.name} ({(tag.score * 100).toFixed(0)}%)
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-gray-50 rounded-lg p-6 text-center">
                    <p className="text-gray-500">
                        Aucune métadonnée disponible pour cet utilisateur.
                    </p>
                </div>
            )}
        </div>
    );
};

export default UserDetails;