import React from 'react';
import { Ressource } from '../../../types/ressource.types';
import { formatDate, cleanTitle } from '../../../utils/formatters';

interface RessourceTableProps {
    ressources: Ressource[];
    onRessourceClick: (ressourceId: string) => void;
}

export const RessourceTable: React.FC<RessourceTableProps> = ({ ressources, onRessourceClick }) => (
    <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
                <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Titre
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Identifiant HAL
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date d'importation
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Metadata
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {ressources.map((ressource) => (
                    <tr 
                        key={ressource._id}
                        onClick={() => onRessourceClick(ressource._id)}
                        className="hover:bg-gray-50 cursor-pointer transition-colors duration-150"
                    >
                        <td className="px-6 py-4 whitespace-normal">
                            <div className="text-sm text-gray-900 hover:text-green-600">
                                {cleanTitle(ressource.titre)}
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                                {ressource.source.identifiant}
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                                {formatDate(ressource.dateImportation)}
                            </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                ressource.halMetadata 
                                ? 'bg-green-100 text-green-800' 
                                : 'bg-gray-100 text-gray-800'
                            }`}>
                                {ressource.halMetadata ? 'Présentes' : 'Absentes'}
                            </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center space-x-4">
                                <a
                                    href={ressource.source.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-green-600 hover:text-green-900"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Voir sur HAL
                                </a>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);