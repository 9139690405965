import React, { useState, useEffect } from 'react';

const HALSyncStatus: React.FC = () => {
    const [isSyncing, setIsSyncing] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const fetchStatus = async () => {
        const response = await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/ressources/sync-metadata-status`, {
            headers: {
                Authorization: `Bearer ${user.token}`,
            }
        });
        const data = await response.json();
        setIsSyncing(data.isSyncing);
        setIsPaused(data.isPaused);
        setTotalItems(data.totalItems);
        setCurrentIndex(data.currentIndex);
    };

    const startSync = async () => {
        await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/ressources/sync-metadata`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user.token}`,
            }
        });
        // Lance le polling
        fetchStatus();
    };

    const pauseSync = async () => {
        await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/ressources/sync-metadata-pause`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user.token}`,
            }
        });
        fetchStatus();
    };

    const resumeSync = async () => {
        await fetch(`${process.env.REACT_APP_URL_API_MACADAMIA}/ressources/sync-metadata-resume`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${user.token}`,
            }
        });
        fetchStatus();
    };

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (isSyncing) {
            // Polling toutes les 2 secondes
            interval = setInterval(() => {
                fetchStatus();
            }, 2000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isSyncing]);

    const progress = totalItems > 0 ? (currentIndex / totalItems) * 100 : 0;

    return (
        <div>
            {!isSyncing && (
                <button onClick={startSync} className="btn btn-primary">
                    Lancer la synchronisation HAL
                </button>
            )}

            {isSyncing && (
                <div>
                    <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                        <div
                            className="bg-blue-600 h-4 rounded-full"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <p>{currentIndex} / {totalItems} ressources traitées</p>

                    {!isPaused ? (
                        <button onClick={pauseSync} className="btn btn-warning">
                            Mettre en pause
                        </button>
                    ) : (
                        <button onClick={resumeSync} className="btn btn-success">
                            Reprendre
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default HALSyncStatus;
