export const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

export const cleanTitle = (title: string) => {
    return title
        .replace(/&#x27E8;/g, '⟨')
        .replace(/&#x27E9;/g, '⟩')
        .replace(/⟨.*?⟩/g, '')
        .trim();
};