import React from 'react';

interface RessourceSearchProps {
    searchQuery: string;
    isSearching: boolean;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSearchSubmit: (e: React.FormEvent) => void;
    onClearSearch: () => void;
}

export const RessourceSearch: React.FC<RessourceSearchProps> = ({
    searchQuery,
    isSearching,
    onSearchChange,
    onSearchSubmit,
    onClearSearch
}) => (
    <form onSubmit={onSearchSubmit} className="mb-6">
        <div className="flex gap-4">
            <div className="flex-1">
                <input
                    type="text"
                    value={searchQuery}
                    onChange={onSearchChange}
                    placeholder="Rechercher par titre ou référence HAL..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
            </div>
            <button
                type="submit"
                disabled={isSearching}
                className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:opacity-50"
            >
                {isSearching ? 'Recherche...' : 'Rechercher'}
            </button>
            {searchQuery && (
                <button
                    type="button"
                    onClick={onClearSearch}
                    className="px-6 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                    Réinitialiser
                </button>
            )}
        </div>
    </form>
);