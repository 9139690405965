import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RessourceSearch } from './RessourceSearch';
import { RessourceTable } from './RessourceTable';
import { Pagination } from './Pagination';
import HALSyncStatus from './HalSyncStatus';
import { Ressource, PaginationData } from '../../../types/ressource.types';

const HAL: React.FC = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [ressources, setRessources] = useState<Ressource[]>([]);
    const [pagination, setPagination] = useState<PaginationData>({
        page: 1,
        limit: 100,
        total: 0,
        pages: 0
    });
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [totalHAL, setTotalHAL] = useState<number>(0);

    const fetchRessources = async (page: number) => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const response = await fetch(
                `${process.env.REACT_APP_URL_API_MACADAMIA}/ressources?page=${page}&limit=100`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            const data = await response.json();
            setRessources(data.ressources);
            setPagination(data.pagination);
        } catch (error) {
            console.error('Erreur lors de la récupération des ressources:', error);
        } finally {
            setLoading(false);
        }
    };

    const searchRessources = async (query: string, page: number = 1) => {
        try {
            setIsSearching(true);
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const response = await fetch(
                `${process.env.REACT_APP_URL_API_MACADAMIA}/ressources/search?q=${encodeURIComponent(query)}&page=${page}&limit=100`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            const data = await response.json();
            setRessources(data.ressources);
            setPagination(data.pagination);
        } catch (error) {
            console.error('Erreur lors de la recherche:', error);
        } finally {
            setIsSearching(false);
        }
    };

    const fetchTotalHALRessources = async () => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            const response = await fetch(
                `${process.env.REACT_APP_URL_API_MACADAMIA}/ressources?source=HAL`,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                    },
                }
            );
            const data = await response.json();
            setTotalHAL(data.pagination.total); // Stocke le total dans un état
        } catch (error) {
            console.error('Erreur lors de la récupération du total des ressources HAL:', error);
        }
    };


    useEffect(() => {
        fetchRessources(1);
        fetchTotalHALRessources();
    }, []);


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            searchRessources(searchQuery.trim());
        } else {
            fetchRessources(1);
        }
    };

    const handleClearSearch = () => {
        setSearchQuery('');
        fetchRessources(1);
    };

    const handlePageChange = (newPage: number) => {
        if (!searchQuery.trim()) {
            fetchRessources(newPage);
        }
    };

    const handleRessourceClick = (ressourceId: string) => {
        navigate(`/ressources/hal/${ressourceId}`);
    };

    if (loading) {
        return (
            <div className="p-8">
                <div className="animate-pulse">
                    <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
                    <div className="space-y-4">
                        <div className="h-24 bg-gray-200 rounded"></div>
                        <div className="h-24 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="p-8">
            <div className="flex items-center mb-6">
                <img src={`${process.env.PUBLIC_URL}/logos/logo-hal.png`} alt="Logo HAL" className="h-10 w-auto mr-4" />
                <h1 className="text-2xl font-bold text-gray-900">
                    Ressources HAL {totalHAL > 0 && `(${totalHAL})`}
                </h1>
            </div>

            <div className="bg-white p-6 rounded-lg shadow mb-8">
                <HALSyncStatus />

                <RessourceSearch
                    searchQuery={searchQuery}
                    isSearching={isSearching}
                    onSearchChange={handleSearchChange}
                    onSearchSubmit={handleSearchSubmit}
                    onClearSearch={handleClearSearch}
                />

                <RessourceTable 
                    ressources={ressources} 
                    onRessourceClick={handleRessourceClick}
                />

                {!searchQuery && (
                    <Pagination
                        pagination={pagination}
                        onPageChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
};

export default HAL;