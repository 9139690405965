import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import LoadingSpinner from '../../common/LoadingSpinner';
import HALMetadata from './HALMetadata';

interface HALMetadata {
    abstract?: string;
    keywords?: string[];
    authors?: string[];
    journal?: {
        title?: string;
        publisher?: string;
        issn?: string;
        volume?: string;
        issue?: string;
        pages?: string;
    };
    publication?: {
        type: string;
        subType?: string;
        date: string;
        language: string;
        domain: string[];
    };
    scientific?: {
        peerReviewed: boolean;
        popularLevel: boolean;
        openAccess: boolean;
        selfArchiving: boolean;
    };
    institutions?: {
        laboratory?: string[];
        university?: string[];
        department?: string[];
    };
    identifiers?: {
        doi?: string;
        isbn?: string;
        hal: {
            id: string;
            version: number;
            uri: string;
        };
    };
    files?: {
        main?: string;
        annexes?: string[];
        fulltext?: string;
    };
    references?: {
        bibtex?: string;
        citation?: string;
        endnote?: string;
    };
}

interface Ressource {
    _id: string;
    titre: string;
    source: {
        nom: string;
        identifiant: string;
        url: string;
    };
    dateImportation: Date;
    halMetadata?: HALMetadata;
}

const HALDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [ressource, setRessource] = useState<Ressource | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchRessource = async () => {
            try {
                const user = JSON.parse(localStorage.getItem('user') || '{}');
                const response = await fetch(
                    `${process.env.REACT_APP_URL_API_MACADAMIA}/ressources/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération de la ressource');
                }

                const data = await response.json();
                setRessource(data);
            } catch (error) {
                console.error('Erreur:', error);
                setError(error instanceof Error ? error.message : 'Une erreur est survenue');
            } finally {
                setLoading(false);
            }
        };

        if (id) {
            fetchRessource();
        }
    }, [id]);

    if (loading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return (
            <div className="p-8">
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
                    {error}
                </div>
            </div>
        );
    }

    if (!ressource) {
        return (
            <div className="p-8">
                <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded">
                    Ressource non trouvée
                </div>
            </div>
        );
    }

    const metadata = ressource.halMetadata;

    return (
        <div className="p-8">
            <div className="mb-6 flex justify-between items-center">
                <div className="flex items-center">
                    <img src={`${process.env.PUBLIC_URL}/logos/logo-hal.png`} alt="Logo HAL" className="h-10 w-auto mr-4" />
                    <h1 className="text-2xl font-bold text-gray-900">
                        Détails de la ressource HAL
                    </h1>
                </div>
                <button
                    onClick={() => navigate('/ressources/hal')}
                    className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
                >
                    Retour à la liste
                </button>
            </div>

            <div className="space-y-6">
                {/* Informations principales */}
                <div className="bg-white shadow rounded-lg p-6">
                    <h2 className="text-xl font-semibold mb-4">Informations principales</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                            <h3 className="text-gray-500">Titre</h3>
                            <p className="font-semibold">{ressource.titre}</p>
                        </div>
                        <div>
                            <h3 className="text-gray-500">Date d'importation</h3>
                            <p className="font-semibold">
                                {format(new Date(ressource.dateImportation), 'dd/MM/yyyy HH:mm')}
                            </p>
                        </div>
                    </div>
                </div>

                {/* Métadonnées générées */}
                {metadata && <HALMetadata metadata={metadata} />}

                {/* Métadonnées HAL brutes */}
                {metadata && (
                    <>
                        {/* Abstract */}
                        {metadata.abstract && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Résumé</h2>
                                <p className="text-gray-700 whitespace-pre-line">{metadata.abstract}</p>
                            </div>
                        )}

                        {/* Publication */}
                        {metadata.publication && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Informations de publication</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <h3 className="text-gray-500">Type</h3>
                                        <p className="font-semibold">{metadata.publication.type}</p>
                                    </div>
                                    {metadata.publication.subType && (
                                        <div>
                                            <h3 className="text-gray-500">Sous-type</h3>
                                            <p className="font-semibold">{metadata.publication.subType}</p>
                                        </div>
                                    )}
                                    <div>
                                        <h3 className="text-gray-500">Date</h3>
                                        <p className="font-semibold">{metadata.publication.date}</p>
                                    </div>
                                    <div>
                                        <h3 className="text-gray-500">Langue</h3>
                                        <p className="font-semibold">{metadata.publication.language}</p>
                                    </div>
                                </div>
                                {metadata.publication.domain && metadata.publication.domain.length > 0 && (
                                    <div className="mt-4">
                                        <h3 className="text-gray-500 mb-2">Domaines</h3>
                                        <div className="flex flex-wrap gap-2">
                                            {metadata.publication.domain.map((domain, index) => (
                                                <span
                                                    key={index}
                                                    className="px-3 py-1 bg-gray-100 text-gray-700 rounded-full text-sm"
                                                >
                                                    {domain}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* Auteurs et Mots-clés */}
                        <div className="bg-white shadow rounded-lg p-6">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                {/* Auteurs */}
                                {metadata.authors && metadata.authors.length > 0 && (
                                    <div>
                                        <h2 className="text-xl font-semibold mb-4">Auteurs</h2>
                                        <ul className="list-disc list-inside space-y-1">
                                            {metadata.authors.map((author, index) => (
                                                <li key={index} className="text-gray-700">{author}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {/* Mots-clés */}
                                {metadata.keywords && metadata.keywords.length > 0 && (
                                    <div>
                                        <h2 className="text-xl font-semibold mb-4">Mots-clés</h2>
                                        <div className="flex flex-wrap gap-2">
                                            {metadata.keywords.map((keyword, index) => (
                                                <span
                                                    key={index}
                                                    className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm"
                                                >
                                                    {keyword}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Journal */}
                        {metadata.journal && Object.keys(metadata.journal).some(key => metadata.journal?.[key as keyof typeof metadata.journal]) && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Informations du journal</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {metadata.journal.title && (
                                        <div>
                                            <h3 className="text-gray-500">Titre du journal</h3>
                                            <p className="font-semibold">{metadata.journal.title}</p>
                                        </div>
                                    )}
                                    {metadata.journal.publisher && (
                                        <div>
                                            <h3 className="text-gray-500">Éditeur</h3>
                                            <p className="font-semibold">{metadata.journal.publisher}</p>
                                        </div>
                                    )}
                                    {metadata.journal.issn && (
                                        <div>
                                            <h3 className="text-gray-500">ISSN</h3>
                                            <p className="font-semibold">{metadata.journal.issn}</p>
                                        </div>
                                    )}
                                    <div className="flex gap-4">
                                        {metadata.journal.volume && (
                                            <div>
                                                <h3 className="text-gray-500">Volume</h3>
                                                <p className="font-semibold">{metadata.journal.volume}</p>
                                            </div>
                                        )}
                                        {metadata.journal.issue && (
                                            <div>
                                                <h3 className="text-gray-500">Numéro</h3>
                                                <p className="font-semibold">{metadata.journal.issue}</p>
                                            </div>
                                        )}
                                        {metadata.journal.pages && (
                                            <div>
                                                <h3 className="text-gray-500">Pages</h3>
                                                <p className="font-semibold">{metadata.journal.pages}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Caractéristiques scientifiques */}
                        {metadata.scientific && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Caractéristiques scientifiques</h2>
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                                    <div className="flex items-center">
                                        <div className={`w-4 h-4 rounded mr-2 ${metadata.scientific.peerReviewed ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        <span>Peer-reviewed</span>
                                    </div>
                                    <div className="flex items-center">
                                        <div className={`w-4 h-4 rounded mr-2 ${metadata.scientific.popularLevel ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        <span>Niveau vulgarisé</span>
                                    </div>
                                    <div className="flex items-center">
                                        <div className={`w-4 h-4 rounded mr-2 ${metadata.scientific.openAccess ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        <span>Open Access</span>
                                    </div>
                                    <div className="flex items-center">
                                        <div className={`w-4 h-4 rounded mr-2 ${metadata.scientific.selfArchiving ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                        <span>Auto-archivage</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Identifiants */}
                        {metadata.identifiers && Object.keys(metadata.identifiers).length > 0 && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Identifiants</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    {metadata.identifiers.doi && (
                                        <div>
                                            <h3 className="text-gray-500">DOI</h3>
                                            <p className="font-semibold">
                                                <a
                                                    href={`https://doi.org/${metadata.identifiers.doi}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 hover:text-blue-800"
                                                >
                                                    {metadata.identifiers.doi}
                                                </a>
                                            </p>
                                        </div>
                                    )}
                                    {metadata.identifiers.isbn && (
                                        <div>
                                            <h3 className="text-gray-500">ISBN</h3>
                                            <p className="font-semibold">{metadata.identifiers.isbn}</p>
                                        </div>
                                    )}
                                    {metadata.identifiers.hal && (
                                        <div>
                                            <h3 className="text-gray-500">HAL ID</h3>
                                            <p className="font-semibold">
                                                <a
                                                    href={metadata.identifiers.hal.uri}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-600 hover:text-blue-800"
                                                >
                                                    {metadata.identifiers.hal.id} (v{metadata.identifiers.hal.version})
                                                </a>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Fichiers */}
                        {metadata.files && (Object.keys(metadata.files).length > 0) && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Fichiers</h2>
                                <div className="space-y-4">
                                    {metadata.files.main && (
                                        <div>
                                            <h3 className="text-gray-500">Fichier principal</h3>
                                            <a
                                                href={metadata.files.main}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                Télécharger
                                            </a>
                                        </div>
                                    )}
                                    {metadata.files.fulltext && (
                                        <div>
                                            <h3 className="text-gray-500">Texte intégral</h3>
                                            <a
                                                href={metadata.files.fulltext}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                Télécharger
                                            </a>
                                        </div>
                                    )}
                                    {metadata.files.annexes && metadata.files.annexes.length > 0 && (
                                        <div>
                                            <h3 className="text-gray-500 mb-2">Annexes</h3>
                                            <ul className="list-disc list-inside space-y-1">
                                                {metadata.files.annexes.map((annexe, index) => (
                                                    <li key={index}>
                                                        <a
                                                            href={annexe}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="text-blue-600 hover:text-blue-800"
                                                        >
                                                            Annexe {index + 1}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Références bibliographiques */}
                        {metadata.references && Object.keys(metadata.references).length > 0 && (
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold mb-4">Références bibliographiques</h2>
                                <div className="space-y-4">
                                    {metadata.references.citation && (
                                        <div>
                                            <h3 className="text-gray-500">Citation</h3>
                                            <p className="font-mono text-sm bg-gray-50 p-3 rounded whitespace-pre-wrap break-all">
                                                {metadata.references.citation}
                                            </p>
                                        </div>
                                    )}
                                    {metadata.references.bibtex && (
                                        <div>
                                            <h3 className="text-gray-500">BibTeX</h3>
                                            <div className="relative max-w-full">
                                                <pre className="font-mono text-sm bg-gray-50 p-3 rounded whitespace-pre-wrap break-all overflow-y-auto max-h-96">
                                                    {metadata.references.bibtex}
                                                </pre>
                                            </div>
                                        </div>
                                    )}
                                    {metadata.references.endnote && (
                                        <div>
                                            <h3 className="text-gray-500">EndNote</h3>
                                            <div className="relative max-w-full">
                                                <pre className="font-mono text-sm bg-gray-50 p-3 rounded whitespace-pre-wrap break-all overflow-y-auto max-h-96">
                                                    {metadata.references.endnote}
                                                </pre>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default HALDetails;