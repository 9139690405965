import React from 'react';

const LettreNumerique: React.FC = () => {
    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold text-gray-900 mb-4">Lettre du Numérique</h1>
            <p className="text-gray-700">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus.
                Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
            </p>
        </div>
    );
};

export default LettreNumerique;